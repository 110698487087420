import LoanSlider from '@/vue/pages/LoanSlider.vue'
import { createApp } from 'vue'

import SwiperPlugin from '@/js/plugins/swiper'

const main = async () => {
  const app = createApp(LoanSlider)
  const root = app.use(SwiperPlugin).mount('#loan-slider')
  return root
}

main().then(() => {})
